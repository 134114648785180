


























import './styles/MemberRights.scss';
import './styles/MemberRightsAdaptive.scss';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { IMemberInfo, IMemberRight } from '@store/modules/project-team/interfaces/Interfaces';
import { ProjectTeamActions } from '@store/modules/project-team/Types';
import { IChangeMemberRightsPostData } from '@store/modules/project-team/interfaces/ApiRequests';
import { UserActionTypes } from '@store/modules/user/Types';
import { IUserState } from '@store/modules/user/Interfaces';

const NSUser = namespace('storeUser');
const NSProject = namespace('storeProject');
const NSProjectTeam = namespace('storeProjectTeam');

@Component({
    name: 'MemberRights',

    components: {
        BaseSelect: () => import('@components/BaseSelect/BaseSelect.vue'),
        BaseSelectOption: () => import('@components/BaseSelect/BaseSelectOption.vue'),
    },
})
export default class MemberRights extends Vue {
    @Prop({ default: false }) userCanChangeRights!: boolean;

    @NSProject.Getter('projectId') projectId!: number;
    @NSProjectTeam.Getter('viewedMember') viewedMember!: IMemberInfo;
    @NSProjectTeam.Getter('memberRights') memberRights!: IMemberRight[];

    @NSProjectTeam.Action(ProjectTeamActions.A_ADD_RIGHT_TO_MEMBER)
    addRightToMember!: (payload: { params: IChangeMemberRightsPostData, right: IMemberRight }) => Promise<any>;

    @NSUser.Getter('userData') userData!: IUserState;
    @NSUser.Action(UserActionTypes.A_SET_USER_RIGHT_ONLY_GSN) setUserRightOnlyGSN!: (userId) => Promise<void>;

    @NSProjectTeam.Action(ProjectTeamActions.A_DELETE_MEMBER_RIGHT)
    deleteMemberRight!: (payload: { params: IChangeMemberRightsPostData, right: IMemberRight }) => Promise<any>;

    viewedMemberRights: IMemberRight[] = [];

    disabledRights: string[] = [];
    disabledSelect: boolean = false;

    mounted() {
        this.viewedMemberRights = this.viewedMemberRightsList;
    }

    get mixedMemberRights(): IMemberRight[] {

        // Добавил расширенный список прав (раньше здесь было просто return this.memberRights)
        // Если возвращать только this.memberRights, будет бага с невозможностью отключить право юзера,
        // которое не попадает в тот список, но присвоено ему

        const output : IMemberRight[] = this.memberRights;
        if (this.viewedMember.rights.length && this.viewedMember.rights.length !== this.memberRights.length) {
            const addedIds = output.map(item => item.uuid);
            const rightsExceptions = ['Администратор', 'администратор'];
            this.viewedMember.rights.forEach(item => {
                if (!addedIds.includes(item.uuid)) {
                    output.push(item);
                }
            });
            return output.filter(item => !rightsExceptions.includes(item.label));
        }
        return this.memberRights;
    }

    //нужно, чтобы были в общем списке прав и списке прав пользователя были одни объекты
    get viewedMemberRightsList() {
        const viewedRights = this.viewedMember.rights.map(right => right.uuid);
        return this.memberRights.filter(right => viewedRights.includes(right.uuid))
    }

    changeRights(right: IMemberRight): void {
        if (this.disabledRights.includes(right.uuid)) return;
        this.disabledRights.push(right.uuid);
        const isMemberOwnsRight = this.isMemberOwnsRight(right);
        const changeMemberRightPostData = {
            params: this.formChangeMemberRightPostData(right),
            right,
        };
        this.disabledSelect = true;
        this.changeRightRequest(changeMemberRightPostData, isMemberOwnsRight)
            .then((response) => {
                if (response.data?.error) {
                    const newViewedMemberRights : any[] = [];
                    this.viewedMemberRights.forEach(item => {
                        if (item.uuid !== right.uuid) {
                            newViewedMemberRights.push(item);
                        }
                    });
                    this.viewedMemberRights = JSON.parse(JSON.stringify(newViewedMemberRights));
                }
                if (this.disabledRights.includes(right.uuid)) {
                    const newDisabledRights : any[] = [];
                    this.disabledRights.forEach(item => {
                        if (item !== right.uuid) {
                            newDisabledRights.push(item);
                        }
                    });
                    this.disabledRights = JSON.parse(JSON.stringify(newDisabledRights));
                }
                this.setUserRightOnlyGSN(this.userData.userInfo.id);
                this.disabledSelect = false;
            })
        .finally(() => {
            this.viewedMemberRights = JSON.parse(JSON.stringify(this.viewedMemberRightsList));
        });
    }

    changeRightRequest(changeMemberRightPostData, isMemberOwnsRight): Promise<any>{
        if (isMemberOwnsRight) return this.deleteMemberRight(changeMemberRightPostData)
        else return this.addRightToMember(changeMemberRightPostData)
    }

    isMemberOwnsRight(right: IMemberRight): boolean {
        return this.viewedMemberRightsList.includes(right);
    }

    formChangeMemberRightPostData(right: IMemberRight): IChangeMemberRightsPostData {
        return {
            appId: this.projectId,
            userId: this.viewedMember.uuid,
            roleId: right.uuid,
        };
    }
}
